<template>
  <div>
    <h2>
      {{ $t('BasicInformation') }}
    </h2>

    <form @submit.prevent
      class="pt-5">
      <div class="mb-3">
        <vs-input
          v-model.trim="name"
          class="w-full required"
          :label="$t('$General.Name')"
          :name="$t('$General.Name')"
          v-validate="'required|max:100'"
          data-vv-validate-on="blur|input"
          icon-no-border
          icon="icon icon-user"
          icon-pack="feather"
          :danger="errors.has($t('$General.Name'))"
          :danger-text="errors.first($t('$General.Name'))"
          val-icon-danger="clear"/>
      </div>

      <div class="mb-3">
        <vs-input
          v-model.trim="email"
          class="w-full required"
          :label="$tc('$General.Email')"
          :name="$tc('$General.Email')"
          type="email"
          v-validate="'required|email'"
          data-vv-validate-on="blur|input"
          icon-no-border
          icon="icon icon-mail"
          icon-pack="feather"
          :danger="emailHasErrors"
          :danger-text="emailErrorMsg"
          val-icon-danger="clear"
          @input="duplicatedEmailError = false"/>
      </div>

      <div class="flex items-center justify-start">
        <vs-button
          :disabled="!updateProfileBasicInfoIsValid || !updateProfileBasicInfoShowCancel"
          @click="updateProfileBasicInfo()">
          {{ $t('$General.Update') }}
        </vs-button>

        <vs-button
          v-show="updateProfileBasicInfoShowCancel"
          class="ml-3 vs-button-cancel-flat"
          color="danger"
          @click="resetBasicModel()">
          {{ $t('$General.Cancel') }}
        </vs-button>
      </div>
    </form>

    <vs-popup
      :title="$t('$Auth.$EmailAccountVerification.VerificationModalTitle', {
        email: this.emailToVerify
      })"
      :active.sync="activeModalVerify">
      <transition name="zoom-fade">
        <user-account-profile-basic-verify-email
          v-if="activeModalVerify"
          :email="emailToVerify"
          :verification-token="changeEmailVerificationToken"
          @close="activeModalVerify=false"
          @verified="updatedProfileEmailValidated()"
          @resend="updateProfileBasicInfo(true)">
        </user-account-profile-basic-verify-email>
      </transition>

    </vs-popup>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UserAccountProfileBasicVerifyEmail from '@/views/modules/user/user-account/user-account-profile/UserAccountProfileBasicVerifyEmail.vue';

/**
 * Component to allow the user view and update its profile basic information
 *
 * @module views/modules/user-account/UserAccountProfileBasic.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} [activeModalVerify=false] - indicate if show or no the verify modal
 * @vue-data {string} [emailToVerify=''] - profile email to verify
 * @vue-data {string} [changeEmailVerificationToken=''] - profile email verification token
 * @vue-data {string} [name=''] - username of user
 * @vue-data {string} [email=''] - email of user
 * @vue-computed {boolean} updateProfileBasicInfoIsValid -
 * indicate if is valid or no update the profile basic information
 * @vue-computed {boolean} updateProfileBasicInfoShowCancel -
 * indicate if cancel button must be show on basic info form
 * @vue-event {void} verifyChangeEmailToken - token to validate the new profile email
 * @vue-event {void} resetBasicModel - set the basic model to the default values
 * @vue-event {void} updateProfileBasicInfo - save the updated profile basic info
 * @vue-event {void} updatedProfileEmailValidated - call on new profile email validated
 */
export default {
  name: 'UserAccountProfileBasic',
  i18n: {
    messages: {
      en: {
        BasicInformation: 'Basic information',
        UpdateBasicInfoNotificationTitle: 'Basic information update',
        UpdateBasicInfoNotificationMsg: 'Basic information updated successfully',
        DuplicatedEmailErrorMsg: 'That email is taken. Try another',
        $ResendVerificationEmailNotify: {
          Title: 'Email sent',
          Msg: 'Verification email sent successfully',
        },
      },
    },
  },
  components: {
    UserAccountProfileBasicVerifyEmail,
  },
  data() {
    return {
      activeModalVerify: false,
      emailToVerify: '',
      changeEmailVerificationToken: '',
      name: '',
      email: '',
      duplicatedEmailError: false,
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.auth.user,
    }),
    emailHasErrors() {
      return this.errors.has(this.$tc('$General.Email')) || this.duplicatedEmailError;
    },
    emailErrorMsg() {
      return this.errors.first(this.$tc('$General.Email')) || this.$t('DuplicatedEmailErrorMsg');
    },
    updateProfileBasicInfoIsValid() {
      return !this.errors.any()
        && this.name !== '' && this.email !== '';
    },
    updateProfileBasicInfoShowCancel() {
      return Object.keys(this.validationFields).length > 0
        ? Object.keys(this.validationFields).some(
          (key) => this.validationFields[key].changed,
        )
        : false;
    },
  },
  watch: {
    activeModalVerify(val) {
      if (!val) {
        this.changeEmailVerificationToken = '';
        this.$route.query.changeEmailToken = '';
      }
    },
  },
  created() {
    this.verifyChangeEmailToken();
    this.resetBasicModel();
  },
  methods: {
    ...mapActions({
      updateProfile: 'auth/updateProfile',
    }),
    async verifyChangeEmailToken() {
      if (this.$route.query.changeEmailToken) {
        const { changeEmailToken } = this.$route.query;

        if (changeEmailToken) {
          this.changeEmailVerificationToken = changeEmailToken;
          this.activeModalVerify = true;
        }

        const uri = window.location.toString();
        if (uri.indexOf('?') > 0) {
          const cleanUri = uri.substring(0, uri.indexOf('?'));
          window.history.replaceState({}, document.title, cleanUri);
        }
      }
    },
    resetBasicModel() {
      this.name = this.authUser.name || '';
      this.email = this.authUser.email || '';
      this.duplicatedEmailError = false;

      this.$validator.reset();
    },
    async updateProfileBasicInfo(fromVerifyModal = false) {
      if (this.errors.any()) {
        return;
      }

      this.$vs.loading({ type: 'radius' });

      try {
        await this.updateProfile({
          name: this.name,
          email: fromVerifyModal ? this.emailToVerify : this.email,
        });

        if (fromVerifyModal) {
          this.$showSuccessActionNotification({
            title: this.$t('$ResendVerificationEmailNotify.Title'),
            text: this.$t('$ResendVerificationEmailNotify.Msg'),
          });
        } else {
          if (this.authUser.email.toLowerCase() !== this.email.toLowerCase()) {
            this.emailToVerify = this.email.toLowerCase();
            this.activeModalVerify = true;
          }

          this.resetBasicModel();

          this.$showSuccessActionNotification({
            title: this.$t('UpdateBasicInfoNotificationTitle'),
            text: this.$t('UpdateBasicInfoNotificationMsg'),
          });
        }
      } catch (e) {
        if (e.response && e.response.data
          && e.response.data.duplicatedEmail) {
          this.duplicatedEmailError = true;
        } else {
          throw e;
        }
      } finally {
        this.$vs.loading.close();
      }
    },
    updatedProfileEmailValidated() {
      this.activeModalVerify = false;
      this.resetBasicModel();
    },
  },
};
</script>
