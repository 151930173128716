<template>
  <div class="user-account-profile-photo-cropper">
    <div class="flex justify-center items-center">
      <vue-image-cropper
        v-model="cropInfo"
        :width="widthAndHeight"
        :height="widthAndHeight"
        :quality="1"
        :zoom-speed="10"
        :file-size-limit="5242880"
        :accept="'.png,.jpg,.jpeg'"
        :placeholder="placeholder"
        :placeholder-font-size="14"
        prevent-white-space
        disable-rotation
        :show-remove-button="false"
        @file-choose="handleFileChoose"
        @image-remove="fileChoose=false"
        @file-size-exceed="handleFileSizeExceed()"
        @file-type-mismatch="handleFileTypeMismatch()"/>
    </div>

    <p
      v-show="fileError"
      class="text-danger text-center mt-3">
      {{ fileError }}
    </p>

    <div
      class="text-center mt-3"
      v-show="fileChoose">
      <div class="flex justify-around flex-col md:flex-row">
        <span>
          <feather-icon
            icon='MoveIcon'
            svgClasses='h-4 w-4'
            class='mr-1' />
          {{ $t('DragToMove') }}
        </span>
        <span>
          <feather-icon
            icon='ZoomInIcon'
            svgClasses='h-4 w-4'
            class='mr-1' />
          {{ zoomMessage }}
        </span>
      </div>
    </div>

    <vs-divider/>

    <div class="flex justify-around">
      <vs-button
        color="gray"
        icon-pack="feather"
        icon="icon-trash"
        @click="removePhoto()">
        {{ removeText }}
      </vs-button>

      <vs-button
        color="primary"
        icon-pack="feather"
        icon="icon-upload-cloud"
        @click="uploadPhoto()">
        {{ uploadText }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import 'vue-croppa/dist/vue-croppa.css';

/**
 * Component to allow the user crop the profile photo to upload
 *
 * @module views/modules/user-account/UserAccountProfilePhotoCropper
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object | any} cropInfo - crop component info
 * @vue-data {boolean} fileChoose - indicate of the user choose a file photo
 * @vue-data {string} fileError - msg to indicate file upload error
 * @vue-computed {string} placeholder - placeholder to show
 * @vue-computed {number} widthAndHeight - width and height of image box
 * @vue-computed {string} zoomMessage - zoom control message
 * @vue-computed {string} removeText - remove button text
 * @vue-computed {string} uploadText - upload button text
 * @vue-event {void} removePhoto - remove current photo
 * @vue-event {void} uploadPhoto - upload selected photo
 * @vue-event {void} handleFileChoose - called on file choose
 * @vue-event {void} handleFileSizeExceed - called on file size error
 * @vue-event {void} handleFileTypeMismatch - called on file type error
 */
export default {
  name: 'UserAccountProfilePhotoCropper',
  i18n: {
    messages: {
      en: {
        DesktopPlaceholder: 'Drag and drop or click to choose a file',
        mobilePlaceholder: 'Touch to choose a file',
        DragDropFile: 'Drag and drop a file',
        DragToMove: 'Drag to move',
        ScrollToZoom: 'Scroll to zoom',
        PinchTwoFingersToZoom: 'Pinch with two fingers to zoom',
        RemovePhoto: 'Remove photo',
        UploadPhoto: 'Upload photo',
        PhotoTypeErrorMsg: 'Photo must be JPG or PNG.',
        PhotoSizeErrorMsg: 'Photo size must be less than 5MB.',
        MissingPhotoErrorMsg: 'You must select a photo to upload.',
        UploadPhotoErrorMsg: 'Error to upload photo. Try again.',
      },
    },
  },
  data() {
    return {
      cropInfo: {},
      fileChoose: false,
      fileError: '',
    };
  },
  computed: {
    placeholder() {
      return (this.$mq === this.$enums.mqBreakpoints.LAPTOP
        || this.$mq === this.$enums.mqBreakpoints.DESKTOP)
        ? this.$t('DesktopPlaceholder')
        : this.$t('mobilePlaceholder');
    },
    widthAndHeight() {
      return this.$mq === this.$enums.mqBreakpoints.MOBILE ? 250 : 300;
    },
    zoomMessage() {
      return (this.$mq === this.$enums.mqBreakpoints.LAPTOP
        || this.$mq === this.$enums.mqBreakpoints.DESKTOP)
        ? this.$t('ScrollToZoom')
        : this.$t('PinchTwoFingersToZoom');
    },
    removeText() {
      return this.$mq === this.$enums.mqBreakpoints.MOBILE
        ? this.$t('$General.Remove')
        : this.$t('RemovePhoto');
    },
    uploadText() {
      return this.$mq === this.$enums.mqBreakpoints.MOBILE
        ? this.$t('$General.Upload')
        : this.$t('UploadPhoto');
    },
  },
  watch: {
    fileChoose(val) {
      if (val) {
        this.fileError = '';
      }
    },
  },
  methods: {
    removePhoto() {
      if (this.fileChoose) {
        this.cropInfo.remove();
      } else {
        this.fileError = '';
      }
    },
    uploadPhoto() {
      if (this.fileChoose) {
        this.cropInfo.generateBlob(
          (blob) => {
            if (blob) {
              this.$emit('cropped', blob);
            } else {
              this.fileError = this.$t('UploadPhotoErrorMsg');
            }
          },
          'image/jpeg',
          0.8,
        );
      } else {
        this.fileError = this.$t('MissingPhotoErrorMsg');
      }
    },
    handleFileChoose() {
      this.fileChoose = true;
    },
    handleFileSizeExceed() {
      this.fileError = this.$t('PhotoSizeErrorMsg');
      this.cropInfo.remove();
      this.fileChoose = false;
    },
    handleFileTypeMismatch() {
      this.fileError = this.$t('PhotoTypeErrorMsg');
      this.cropInfo.remove();
      this.fileChoose = false;
    },
  },
};
</script>

<style lang="scss">
.user-account-profile-photo-cropper {
  .croppa-container {
    border-radius: 150px;

    &.croppa--has-target {
      canvas {
        border-radius: 150px;
      }
    }
  }
}
</style>
