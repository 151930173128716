<template>
  <vx-card no-shadow>
    <div class="vx-row mb-base pb-base lg:pb-0">
      <div class="vx-col w-full">
        <user-account-profile-photo/>
      </div>
    </div>

    <div class="vx-row mb-base pb-base">
      <div class="vx-col w-full lg:w-1/2">
        <user-account-profile-basic/>
      </div>

      <div class="vx-col w-full lg:w-1/2 mt-base pt-base lg:mt-0 lg:pt-0">
        <user-account-profile-notification-preferences/>
      </div>
    </div>
  </vx-card>
</template>

<script>
import UserAccountProfilePhoto from '@/views/modules/user/user-account/user-account-profile/UserAccountProfilePhoto.vue';
import UserAccountProfileBasic from '@/views/modules/user/user-account/user-account-profile/UserAccountProfileBasic.vue';
import UserAccountProfileNotificationPreferences from '@/views/modules/user/user-account/user-account-profile/UserAccountProfileNotificationPreferences.vue';

/**
 * Component to allow the user view and update its profile
 *
 * @module views/modules/user-account/UserAccountProfileGeneral
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'UserAccountProfileGeneral',
  components: {
    UserAccountProfileBasic,
    UserAccountProfilePhoto,
    UserAccountProfileNotificationPreferences,
  },
  created() {
    window.history.replaceState({}, null, '/account/profile');
  },
};
</script>
