<template>
  <div>
    <template v-if="verificationToken">
      <vs-alert
        color="primary"
        icon-pack="feather"
        icon="icon-info"
        :active="true">
        {{ $t('VerificationEmailByLinkMsg') }}
      </vs-alert>
    </template>
    <template v-else>
      <vs-alert
        color="success"
        icon-pack="feather"
        icon="icon-mail"
        :active="!invalidCodeError">
        <p> {{ $t('VerifyEmailAlertMsg') }}
          <a
            href="#"
            class="ml-0"
            @click.prevent="$emit('resend')">
            {{ $t('ResendNewVerificationEmail') | lowercase }}
          </a>.
        </p>
      </vs-alert>
      <vs-alert
        color="warning"
        icon-pack="feather"
        icon="icon-alert-triangle"
        :active="!!invalidCodeError">
        <p>
          {{ $t('EnteredCodeInvalidAlertMsg') }}
          <a
            href="#"
            class="ml-0"
            @click.prevent="$emit('resend')">
            {{ $t('ResendNewVerificationEmail') | lowercase }}
          </a>.
        </p>
      </vs-alert>
      <form @submit.prevent>
        <div class="vx-row mb-6 mt-6">
          <div class="vx-col w-full">
            <vs-input
              v-model="verificationCode"
              class="w-full required"
              :label="$t('VerificationCode')"
              :name="$t('VerificationCode')"
              v-validate="'required|digits:6'"
              data-vv-validate-on="blur|input"
              :danger="verifyCodeInputIsInvalid"
              :danger-text="invalidEnteredCodeErrorMsg"
              val-icon-danger="clear"
              @input="invalidCodeError = ''"/>
          </div>
        </div>

        <base-form-footer-action-buttons
          @save="verifyEmail"
          @cancel="$emit('close')">
          <template v-slot:save>
            {{ $t('$General.Verify')}}
          </template>
        </base-form-footer-action-buttons>
      </form>
    </template>
  </div>
</template>

<script>
// Components
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';

// Services
import { mapActions } from 'vuex';

/**
 * Component to verify a new profile user email
 *
 * @module views/modules/user-account/UserAccountProfileBasicVerifyEmail
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} email - user profile email to verify
 * @vue-prop {string} verificationToken - verification token
 * @vue-data {string} verificationCode - verification code entered
 * @vue-data {boolean} invalidEnteredCode - indicate if the entered code is invalid or no
 * @vue-computed  {boolean} verifyCodeInputIsInvalid - indicate is code input field is invalid
 * @vue-computed  {string} invalidEnteredCodeErrorMsg - error to show for entered an invalid code
 * @vue-event  {void} verifyEmail - check entered verification code
 */
export default {
  name: 'UserAccountProfileBasicVerifyEmail',
  i18n: {
    messages: {
      en: {
        VerificationCode: 'Verification code',
        VerifyEmailAlertMsg: 'We have sent you a verification email with a code and a link. Please click the link in the email or enter the code below. If you have not received the email, you can',
        VerificationEmailByLinkMsg: 'We are verifying and validating your new email. Please wait a few seconds.',
        EnteredCodeInvalidAlertMsg: 'Email account could not be verified. Make sure the code is correct and valid. If you have not received the email, you can',
        UpdateBasicEmailNotificationTitle: 'Email profile update',
        UpdateBasicEmailNotificationMsg: 'Email profile updated successfully',
        UpdateBasicEmailTokenErrorNotificationTitle: 'Email profile update',
        UpdateBasicEmailTokenErrorNotificationMsg: 'Verification link expired or invalid',
        ResendNewVerificationEmail: 'Resend here',
      },
    },
  },
  components: {
    BaseFormFooterActionButtons,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    verificationToken: {
      type: String,
    },
  },
  data() {
    return {
      verificationCode: '',
      invalidCodeError: '',
    };
  },
  computed: {
    verifyCodeInputIsInvalid() {
      return this.errors.has(this.$t('VerificationCode'))
        || !!this.invalidCodeError;
    },
    invalidEnteredCodeErrorMsg() {
      return this.errors.first(this.$t('VerificationCode')) || this.invalidCodeError;
    },
  },
  created() {
    if (this.verificationToken) {
      this.verifyEmail();
    }
  },
  methods: {
    ...mapActions({
      verifyProfileEmail: 'auth/verifyProfileEmail',
    }),
    async verifyEmail() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid && !this.verificationToken) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
        return;
      }

      this.$vs.loading({ type: 'radius' });

      try {
        await this.verifyProfileEmail({
          email: this.email,
          code: this.verificationCode,
          token: this.verificationToken,
        });

        this.$showSuccessActionNotification({
          title: this.$t('UpdateBasicEmailNotificationTitle'),
          text: this.$t('UpdateBasicEmailNotificationMsg'),
        });

        this.verificationCode = '';
        this.$emit('verified');
      } catch (err) {
        if (this.verificationToken) {
          this.$showGeneralErrorNotification({
            title: this.$t('UpdateBasicEmailTokenErrorNotificationTitle'),
            text: this.$t('UpdateBasicEmailTokenErrorNotificationMsg'),
          });
          this.$emit('close');
        } else if (err.response.data.statusCode === 400 && err.response.data.invalidCode) {
          this.invalidCodeError = err.response.data.message;
        }
      } finally {
        this.$vs.loading.close();
      }
    },
  },
};
</script>
