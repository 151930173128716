<template>
  <div>
    <h2>
      {{ $t('NotificationPreferences') }}
    </h2>

    <form @submit.prevent class="mt-base">
      <div class="mb-3 flex items-center">
        <vs-checkbox
          v-model="specialOffers"
          class="inline-flex mt-0">
        </vs-checkbox>
        <span>
          {{ $t('SpecialOffers') }}
        </span>
      </div>

      <div class="mb-3 flex items-center">
        <vs-checkbox
          v-model="newFeaturesAvailable"
          class="inline-flex mt-0">
        </vs-checkbox>
        <span>

          {{ $t('NewFeaturesAvailable') }}
        </span>
      </div>

      <div class="flex items-center justify-start mt-base">
        <vs-button
        @click="updateFlagsNotification">
          {{ $t('$General.Update') }}
        </vs-button>
      </div>
    </form>
  </div>

</template>

<script>

import { mapActions, mapGetters } from 'vuex';

/**
 * Component to allow the user view and update its notification preferences
 *
 * @module views/modules/user-account/UserAccountProfileNotificationPreferences.vue
 * @author Jesus Niño <janmjesus@gmail.com>
 *
 * @vue-data {boolean} [specialOffers=false] - Indicate the value of the checkbox of preference
 * @vue-data {boolean} [newFeaturesAvailable=false] - Indicate the value of the checkbox of preference
 * @vue-event {void} updateFlagsNotification - Method to update the notification preferences
 */

export default {
  name: 'UserAccountProfileNotificationPreferences',
  i18n: {
    messages: {
      en: {
        SpecialOffers: 'Special offers',
        NewFeaturesAvailable: 'New features available',
        NotificationPreferences: 'Notification preferences',
        UpdateNotificationPreferencesInfTitle: 'Notification preferences update',
        UpdateNotificationPreferencesMsg: 'Notification preferences updated successfully',
      },
    },
  },
  data() {
    return {
      specialOffers: false,
      newFeaturesAvailable: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserFlag: 'auth/getUserFlag',
    }),

  },
  created() {
    this.loadPreferenceNotifications();
  },
  methods: {
    ...mapActions({
      updateNotificationPreferences: 'auth/acceptedTermsAndConditions',
    }),
    loadPreferenceNotifications() {
      this.specialOffers = this.getUserFlag('specialOffers');
      this.newFeaturesAvailable = this.getUserFlag('newFeaturesAvailable');
    },
    async updateFlagsNotification() {
      this.$vs.loading({ type: 'radius' });
      await this.updateNotificationPreferences({
        specialOffers: this.specialOffers,
        newFeaturesAvailable: this.newFeaturesAvailable,
      });
      this.$vs.loading.close();

      this.$showSuccessActionNotification({
        title: this.$t('UpdateNotificationPreferencesInfTitle'),
        text: this.$t('UpdateNotificationPreferencesMsg'),
      });
    },
  },
};

</script>
